import './../public/App_Themes/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import application from './../public/Resources/script/app.js'
import $event from './event.js'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
    locale: 'fr',
    allowComposition: true, // you need to specify that!
    messages: {
        en: {
        hello: 'page Home.'
        },
        fr: {
        hello: 'page accueil.'
        }
    }
})

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import cHeader from './components/content-header.vue'
import cFooter from './components/content-footer.vue'


const app = createApp(App);
app.component('cHeader', cHeader);
app.component('cFooter', cFooter);

const vuetify = createVuetify({
    components,
    directives,
});
app.use(vuetify);
app.use(application);
if(!$App.event){ $App.event = $event; }
app.use(router);
app.use(i18n)


app.mount('#app');

//createApp(App).use(router).mount('#app')
