<template>
  <v-app>
    <v-main>
      <Cookie/>
      <cHeader />
      <div style="height: 80px;"></div>
      <router-view/>
      <cFooter />
    </v-main>
  </v-app>

</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
  import Cookie from '../src/components/cookie.vue';
  export default {
    name: 'App',
    data: () => ({}),
    mounted: async function () {},
    methods:{},
    components: {
      Cookie,
    }

  }
</script>



