<template>
  <div>
    <!--Section1 Staff-->
    <section class="grid-pad">    
      <div class="textPad">
        <h1 class="policyTitle" v-html="$t('policy.pageTitle')"></h1>
        <v-row >
          <v-col>      
            <p v-html="$t('policy.p1')"></p>
            <p v-html="$t('policy.p2')"></p>
            <p v-html="$t('policy.p3')"></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="txt-l"  v-html="$t('policy.s2Title')"></h2>
            <p  v-html="$t('policy.s2P1')"></p>
            <p  v-html="$t('policy.s2P2')"></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="txt-c" v-html="$t('policy.s3Title')"></h2>
            <p v-html="$t('policy.s3P1')"></p>
            <ul class="grid-pad f-primary">
              <li v-html="$t('policy.s3Li1')"></li>
              <li v-html="$t('policy.s3Li2')"></li>
              <li v-html="$t('policy.s3Li3')"></li>
              <li v-html="$t('policy.s3Li4')"></li>
              <li v-html="$t('policy.s3Li5')"></li>
            </ul>
            <p v-html="$t('policy.s3P2')"></p>
          </v-col>
        </v-row>
        <v-row class="mt-16">
          <v-col cols="12" class="d-lg-flex flex-lg-row">
            <v-col style="position:relative;">
              <div class="section1Photo4"></div>
              <h2 class="txt-c section1TextLogo" v-html="$t('policy.s4Title')"></h2>
            </v-col>
            <v-col class="txt-c">
              <p class="section1Text3 f-primary" v-html="$t('policy.s4P1')"></p>
              <p class="section1Text2 mt-8"  v-html="$t('policy.s4P2')"></p>
              <!--
              <p class="section1Text2"  v-html="$t('policy.s4P3')"></p>
              <p class="section1Text2 f-nobold"  v-html="$t('policy.s4P4')"></p>-->
            </v-col>
          </v-col>
          <v-col>
            <p  v-html="$t('policy.s4P5')"></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 v-html="$t('policy.s5Title')"></h2>
            <p v-html="$t('policy.s5P1')"></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="txt-r" v-html="$t('policy.s6Title')"></h2>
            <p v-html="$t('policy.s6P1')"></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="txt-l" v-html="$t('policy.s7Title')"></h2>
            <p v-html="$t('policy.s7P1')"></p>
          </v-col>
        </v-row>
        <v-row>
          <v-coL>
            <h2 class="txt-r" v-html="$t('policy.s8Title')"></h2>
            <p v-html="$t('policy.s8P1')"></p>
            <p v-html="$t('policy.s8P2')"></p>
              <ul>
                <li><p v-html="$t('policy.s8Li1')"></p></li>
                <li><p v-html="$t('policy.s8Li2')"></p></li>
                <li><p v-html="$t('policy.s8Li3')"></p></li>
              </ul>   
          </v-coL>
        </v-row>
        <v-row>
          <v-col>
            <h2 v-html="$t('policy.s9Title')"></h2>
            <p v-html="$t('policy.s9P1')"></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="txt-c" v-html="$t('policy.s10Title')"></h2>
            <p v-html="$t('policy.s10P1')"></p>
          </v-col>
        </v-row>
      </div>

    </section>
  </div>
</template>
<style scoped>
  h2 {font-weight: 700;}
  .policyTitle {font-size: 72px; text-align: center; font-family: argentlight;}
  .fixingTop {height: 150px;}
  /*.topBanner {height: 500px; background-image: url('../../public/Resource/Banner/bannerPolicy.jpg'); background-position: center center; background-repeat: no-repeat; background-size: cover;}*/
  .topBannerColor { height: 500px; width: 100%; background-color: rgba(var(--secondary),0.5);}
  .textPad { padding: 0px 200px !important;}
  p { font-size:18px; text-align:justify; color: rgb(var(--secondairy)); margin: 15px 0px 15px 0px !important;}
  ul {padding: 0px 50px !important;}
  ul li { text-align: left; }
  .section1Text {font-size:40px; text-align:center; color: rgb(var(--secondairy));}
  .section1Text2 {font-size:18px; text-align:center; color: rgb(var(--secondairy));}
  .section1Text3 {font-size:36px; font-weight:700; text-align:center; margin: 0px 0px 0px 0px !important; font-family: argentReg;}
  .section1TextLogo {position:absolute; width:100%; top:50%; left:50%; transform:translate(-50%, -50%); font-family:Oswald; font-weight:700; font-size:40px;}
    @media handheld, only screen and (max-width: 600px) {
      .textPad { padding: 0px 10px !important;}
    }
    @media handheld, only screen and (max-width: 960px) {
      .textPad { padding: 0px 10px !important;}
      .section1TextLogo {position:relative; font-size:32px;}
      .section1Text3 {font-size:36px; font-weight:700; text-align:center; margin: 0px 0px 0px 0px !important;}
      h2 {font-size: 32px;}
      h3 {font-size: 32px;}
      .policyTitle {font-size: 32px;}
    }
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {
      .textPad { padding: 0px 100px;}
      .section1TextLogo {position:relative; font-size:32px;}
    }
</style>
<script>
import translations from '../../public/Resources/i18n/policy.json';
export default {
  name: 'Policy',
  data:()=>({}),
  mounted() {
    this.$i18n.mergeLocaleMessage('fr', translations.fr);
    this.$i18n.mergeLocaleMessage('en', translations.en);
  },
  methods:{},
  components: { }
}
</script>
