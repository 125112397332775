<template>
    <div>
        <div>
            <div class="Footer">
                <v-row class=" " no-gutters>
                    <v-col class="d-flex align-center colLogo"><img class="logoCompagny" src="/Resources/images/congres/CIEF_logo.png"  title="logo Cindy Badier" alt="visuel de l'entreprise" /></v-col>
                    <v-col class="d-flex align-center" >
                        <h3 class="copywrite">© 2024 Cindy Badier</h3>                
                    </v-col>
                    <v-col class="d-flex align-center justify-end footerPage">
                        <a @click="openCookie()">
                            <h3 class="">Mes témoins</h3>   
                        </a>
                        <router-link to="/PolitiqueDeConfidentialiter">
                            <h3 class="">Politique de confidentialité</h3>   
                        </router-link>

                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
  </template>
  <style scoped>
        .v-row { height: 100%; }
        .v-col { height: inherit;}
        .Footer { margin-top: 10px; padding: 10px 25px; height: 80px; width: 100%; background-color: white; box-shadow: 1px 0px 10px rgb(var(--secondary)); overflow: hidden; }
        .colLogo { max-width: 80px;}
        .logoCompagny { height: 95%; width: auto; }
        .copywrite { padding: 0px 5px 0px 150px; font-family: serif; font-size: 12px; }
        .footerPage a h3 { padding: 0px 5px; font-family: serif; font-size: 12px; }
        .footerPage a h3:hover { text-decoration: underline; }
        a {cursor: pointer;}
        @media handheld, only screen and (max-width: 600px) {}
        @media handheld, only screen and (max-width: 960px) {}
        @media handheld, only screen and (max-width: 1279px) {}
        @media handheld, only screen and (max-width: 1378px) {}
  </style>
  <script>
  
  export default {
    name: 'Footer',
    data:()=>({}),
    mounted() {},
    methods:{
        openCookie: function(){ $App.event.$emit('openCookie'); }
    },
    components: { }
  }
  </script>
  